<template>
  <div class="c26">
    <p class="c3"><span class="c19">CONTRATO DE ADHESI&Oacute;N</span></p>
    <p class="c1"><span class="c19"></span></p>
    <p class="c1"><span class="c19"></span></p>
    <p class="c14">
      <span class="c29">SOLICITUD DE PR&Eacute;STAMO PERSONAL</span>
    </p>
    <p class="c18"><span class="c5"></span></p>
    <a id="t.a7d42af954e3195ce32339bbeff5b82a60e3e807"></a><a id="t.0"></a>
    <table class="c28">
      <tbody>
        <tr class="c16">
          <td class="c20" colspan="6" rowspan="1">
            <p class="c10"><span class="c12">Nombre y Apellido: </span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">DNI:</span></p>
          </td>
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">CUIT / CUIL:</span></p>
          </td>
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Nacionalidad:</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Fecha de Nacimiento:</span></p>
          </td>
          <td class="c9" colspan="4" rowspan="1">
            <p class="c10">
              <span class="c12">Direcci&oacute;n de email:</span>
            </p>
          </td>
        </tr>
        <tr class="c11">
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Sexo:</span></p>
          </td>
          <td class="c9" colspan="4" rowspan="1">
            <p class="c10"><span class="c12">Estado Civil:</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c21" colspan="1" rowspan="2">
            <p class="c10"><span class="c12">Domicilio:</span></p>
          </td>
          <td class="c7" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Calle:</span></p>
          </td>
          <td class="c7" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Altura:</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c10"><span class="c12">Piso y Depto.:</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c7" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Localidad:</span></p>
          </td>
          <td class="c7" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">CP:</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c10"><span class="c12">Provincia:</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Tel&eacute;fono:</span></p>
          </td>
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Celular:</span></p>
          </td>
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Laboral:</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Rel. laboral:</span></p>
          </td>
          <td class="c9" colspan="4" rowspan="1">
            <p class="c10"><span class="c12">Nombre de la empresa:</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Domicilio laboral:</span></p>
          </td>
          <td class="c9" colspan="4" rowspan="1">
            <p class="c10 c30"><span class="c12"></span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c20" colspan="6" rowspan="1">
            <p class="c10">
              <span class="c12"
                >Cargo, T&iacute;tulo Profesional u Ocupaci&oacute;n:</span
              >
            </p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Ingreso mensual:</span></p>
          </td>
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Otros ingresos:</span></p>
          </td>
          <td class="c6" colspan="2" rowspan="1">
            <p class="c10"><span class="c12">Destino pr&eacute;stamo:</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c20" colspan="6" rowspan="1">
            <p class="c24"><span class="c12"></span></p>
            <p class="c31">
              <span class="c12"
                >Solicitud de Pr&eacute;stamo Personal &ndash; T&eacute;rminos y
                condiciones</span
              >
            </p>
            <p class="c24"><span class="c12"></span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c20" colspan="6" rowspan="1">
            <p class="c10">
              <span class="c12"
                >Solicit&oacute; un importe de $ _________ en __ cuotas
                mensuales y consecutivas de $ _______ (*)</span
              >
            </p>
            <p class="c10">
              <span class="c12"
                >siendo el monto total a reintegrar $ ___________</span
              >
            </p>
            <p class="c10">
              <span class="c12"
                >T.E.M.: _______ % T.N.A.: ______ % T.E.A.: ______% Gastos
                Administrativos: _____ %</span
              >
            </p>
            <p class="c10 c30"><span class="c12"></span></p>
            <p class="c10"><span class="c12">% OTROS GASTOS: $____</span></p>
          </td>
        </tr>
        <tr class="c16">
          <td class="c20" colspan="6" rowspan="1">
            <p class="c24"><span class="c15"></span></p>
            <p class="c31">
              <span class="c17">(*</span><span class="c17">) El</span
              ><span class="c15"
                >&nbsp;pr&eacute;stamo se amortiza en cuotas fijas mensuales,
                bajo el esquema del sistema franc&eacute;s. Abona tambi&eacute;n
                IVA, gastos seg&uacute;n detalle en esta solicitud.</span
              >
            </p>
            <p class="c24"><span class="c15"></span></p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c14 c30"><span class="c5"></span></p>
    <p class="c1"><span class="c19"></span></p>
    <p class="c33">
      <span class="c19">T&Eacute;RMINOS Y CONDICIONES GENERALES </span>
    </p>
    <p class="c1"><span class="c19"></span></p>
    <p class="c3"><span class="c19">I. Definiciones.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Las siguientes expresiones tendr&aacute;n en los presentes
        T&eacute;rminos y Condiciones el siguiente significado que se les asigna
        en cada caso, a menos que el contexto requiera otra
        interpretaci&oacute;n:</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span>&nbsp;1. &ldquo;</span><span>PRESTER</span
      ><span
        >&rdquo;, refiere a SIFT S.A., sociedad incorporada bajo las leyes de la
        Argentina, registrada ante la IGJ, bajo el N&uacute;mero 22758, del
        Libro 82 de S.A., CUIT 33-71544137-9; con domicilio en 25 de Mayo 306,
        Piso 2, CP 1002, Ciudad Aut&oacute;noma de Buenos Aires, Argentina.
        &ldquo;PRESTER&rdquo; es una marca registrada y denominaci&oacute;n de
        una aplicaci&oacute;n m&oacute;vil y una p&aacute;gina web (o s</span
      ><span>itio)</span
      ><span class="c0"
        >, y un sistema inform&aacute;tico de propiedad exclusiva de SIFT S.A.
        SIFT S.A. est&aacute; registrada en el Banco Central de la
        Rep&uacute;blica Argentina como Proveedor No Financiero de
        Cr&eacute;dito bajo el n&uacute;mero de entidad 55257.
        &ldquo;Usuario&rdquo; y/o &ldquo;Usted&rdquo; hacen referencia a vuestra
        persona, en tanto utilice el sitio y la aplicaci&oacute;n PRESTER,
        solicite asistencia de dinero de &ldquo;PRESTER&rdquo; y/o la reciba
        (&ldquo;Pr&eacute;stamo&rdquo;) y/o utilice cualquiera de los servicios
        brindados a trav&eacute;s del sitio y la &ldquo;aplicaci&oacute;n
        PRESTER&rdquo; &nbsp;(los &ldquo;Servicios&rdquo;).</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19"
        >&nbsp;II. Aspectos generales. Acceso al sitio y aplicaci&oacute;n
        PRESTER.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >1. El presente documento establece los t&eacute;rminos y condiciones
        generales (los &ldquo;T&eacute;rminos y Condiciones Generales&rdquo;)
        que rigen (i) el acceso al sitio &ldquo;www</span
      ><span>.prester</span
      ><span>.com.ar&rdquo; y la aplicaci&oacute;n m&oacute;vil &ldquo;</span
      ><span class="c0"
        >PRESTER&rdquo; (en adelante &ldquo;el sitio&rdquo; y la
        &ldquo;aplicaci&oacute;n PRESTER&rdquo;) y (ii) la utilizaci&oacute;n de
        cualquiera de los productos o servicios (incluyendo Pr&eacute;stamos y
        Financiaciones) disponibles o accesibles a trav&eacute;s del sitio y la
        aplicaci&oacute;n PRESTER ofrecidos por PRESTER (los
        &ldquo;Servicios&rdquo;).
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >2. El Usuario reconoce que antes de aceptar los T&eacute;rminos y
        Condiciones ha tomado pleno conocimiento de los mismos, ha podido
        acceder a los mismos al in</span
      ><span
        >gresar al sitio web como as&iacute; tambi&eacute;n pudo leerlos y/o
        descargarlos durante el proceso de solicitud </span
      ><span>por los canales detallados previamente</span
      ><span
        >, cuenta con copia de ellos a su disposici&oacute;n, pudiendo
        guardarlos en su ordenador, imprimirlos o retirar copia firmada
        por</span
      ><span class="c0"
        >&nbsp;PRESTER en el domicilio especial fijado por la misma.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >3. El Usuario reconoce y acepta que los presentes T&eacute;rminos y
        Condiciones se convienen sobre la base del art. 1106 del C&oacute;digo
        Civil y Comercial de la Naci&oacute;n, la buena fe contractual y el
        deber de colaboraci&oacute;n mutua de las partes.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >4. El Usuario reconoce y acepta que en su relaci&oacute;n contractual
        con PRESTER, a trav&eacute;s del sitio y la aplicaci&oacute;n PRESTER es
        posible por la implementaci&oacute;n de tecnolog&iacute;as de
        comunicaci&oacute;n que permiten brindar los servicios del sitio y la
        aplicaci&oacute;n PRESTER y por lo tanto reconoce y acepta de manera
        expresa la utilizaci&oacute;n del medio electr&oacute;ni</span
      ><span class="c0"
        >co, en detrimento del soporte papel, para la comunicaci&oacute;n de
        informaciones, t&eacute;rminos y condiciones generales, y otros del
        presente contrato que se suscribe.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >5. El Usuario declara contar con un soporte electr&oacute;nico y/o
        tecnolog&iacute;a adecuada y necesaria para realizar las operaciones
        aqu&iacute; descriptas por intermedio del sitio y aplicaci&oacute;n </span
      ><span>PRESTER</span><span class="c0">. </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span>6. Quien accede al sitio y aplicaci&oacute;n </span
      ><span>PRESTER</span
      ><span
        >&nbsp;y desea registrarse en ellos para utilizar sus Servicios debe
        aceptar expresamente todas las cl&aacute;usulas establecidas en estos
        T&eacute;rminos y Condiciones Generales como condici&oacute;n para el
        ingreso y utilizaci&oacute;n del sitio y la aplicaci&oacute;n </span
      ><span>PRESTER</span><span class="c0">. </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >7. En caso que el Usuario no acepte estos T&eacute;rminos y
        Condiciones, no podr&aacute; utilizar los Servicios que en el sitio y
        aplicaci&oacute;n </span
      ><span>PRESTER</span><span class="c0">&nbsp;se ofrecen. </span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <p class="c3">
      <span>8. El acceso al sitio y la aplicaci&oacute;n </span
      ><span>PRESTER</span
      ><span
        >&nbsp;s&oacute;lo est&aacute; disponible para las personas humanas que
        (i) sean mayores de dieciocho (18) a&ntilde;os, y (ii) tengan capacidad
        legal para contratar. Por ello no podr&aacute;n operar en el sitio y la
        aplicaci&oacute;n </span
      ><span>PRESTER</span
      ><span class="c0"
        >&nbsp;las personas jur&iacute;dicas, los menores de edad o las personas
        temporal o definitivamente inhabilitadas para contratar.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span>9. </span><span>PRESTER</span
      ><span
        >&nbsp;se reserva el derecho de modificar los presentes T&eacute;rminos
        y Condiciones de acuerdo a sus necesidades y las condiciones del
        mercado. Dichas modificaciones estar&aacute;n disponibles en cada caso
        en el sitio y la aplicaci&oacute;n </span
      ><span>PRESTER</span
      ><span
        >&nbsp;y entrar&aacute;n en vigencia a partir de que sean publicadas en
        el sitio y la aplicaci&oacute;n </span
      ><span>PRESTER</span
      ><span class="c0"
        >&nbsp;o notificadas al Usuario por cualquier medio, lo que ocurra
        primero.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >10. &nbsp;PRESTER podr&aacute; continuar, discontinuar o cambiar
        cualquier producto o servicio ofrecido en el sitio y la
        aplicaci&oacute;n PRESTER &nbsp;cuando lo considere necesario. Asimismo,
        PRESTER se reserva el derecho de cambiar, mejorar o corregir la
        informaci&oacute;n, los contenidos y las descripciones y explicaciones
        en el sitio y aplicaci&oacute;n PRESTER , y suspender y/o negar el
        acceso al sitio y aplicaci&oacute;n PRESTER &nbsp;por razones de
        mantenimiento (programado o no programado). En caso que un
        Pr&eacute;stamo o servicio est&eacute; concertado y las modificaciones
        incidan relevantemente en las condiciones en que el mismo fue otorgado,
        &eacute;stas ser&aacute;n informadas al Usuario a la mayor brevedad
        posible por e-mail y se concretar&aacute;n de acuerdo a las
        disposiciones que le sean aplicables. En caso que el Usuario no acepte
        las modificaciones, deber&aacute; comunicar fehacientemente a PRESTER
        &nbsp;dentro de los diez (10) d&iacute;as corridos de haber sido
        notificado y podr&aacute; dar por rescindido el o los Pr&eacute;stamos
        y/o Servicios otorgados por medio del sitio y aplicaci&oacute;n PRESTER
        . A tales efectos, el Usuario tendr&aacute; un m&aacute;ximo de quince
        (15) d&iacute;as corridos para pre-cancelar el saldo adeudado, a partir
        del momento en que se comunique la rescisi&oacute;n.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19"
        >&nbsp;III. Relaci&oacute;n jur&iacute;dica entre las Partes.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >12. Las partes acuerdan que conforme a los art&iacute;culos 286, 288,
        1105, 1106 y concordantes del C&oacute;digo Civil y Comercial de la
        Naci&oacute;n, los presentes T&eacute;rminos y Condiciones Generales, y
        su aceptaci&oacute;n por parte del Usuario v&iacute;a electr&oacute;nica
        o inform&aacute;tica, son un compromiso v&aacute;lido y legal entre el
        Usuario y PRESTER , en especial, pero no limitado, en relaci&oacute;n a
        los Pr&eacute;stamos y/o Servicios que el Usuario solicite a PRESTER
        &nbsp;y/o le sean otorgados o prestados por PRESTER &nbsp;, o por
        cualquiera de las empresas proveedoras de cr&eacute;ditos que
        intervengan a trav&eacute;s del sitio y aplicaci&oacute;n PRESTER .
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >13. El Usuario que desee utilizar el sitio y la aplicaci&oacute;n
        PRESTER &nbsp;deber&aacute; completar los datos necesarios para realizar
        una solicitud u operar con algunos de los o Servicios del sitio y la
        aplicaci&oacute;n PRESTER , estos son como m&iacute;nimo su DNI, Nombre
        y Apellido, Domicilio completo, N&uacute;mero de tel&eacute;fono celular
        o m&oacute;vil, y otros que ser&aacute;n requeridos por PRESTER &nbsp;al
        momento de la solicitud por medio del sitio y la aplicaci&oacute;n
        PRESTER. Dichos datos tendr&aacute;n el tratamiento especificado en el
        apartado VII del presente documento.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >14. Cuando los usuarios accedan por primera vez a la aplicaci&oacute;n
        o al sitio PRESTER &nbsp;deber&aacute;n ingresar mediante su cuenta de
        Facebook, Google, Twitter o bien por otro correo electr&oacute;nico.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >15. PRESTER , a trav&eacute;s del sitio y la aplicaci&oacute;n PRESTER
        , verificar&aacute; la pertenencia del n&uacute;mero celular mediante el
        env&iacute;o de un PIN v&iacute;a SMS que luego el usuario deber&aacute;
        ingresar manualmente</span
      ><span>&nbsp;en el sitio o la aplica</span
      ><span
        >ci&oacute;n, o que podr&aacute; ser detectado autom&aacute;ticamente
        por &eacute;sta </span
      ><span>&uacute;ltima,</span
      ><span class="c0"
        >&nbsp;siempre que la funcionalidad de los sistemas de
        comunicaci&oacute;n as&iacute; se lo permitan, a fin de que &eacute;ste
        proceda a validar su aparato y l&iacute;nea. Seguidamente, tanto el
        sitio como la &nbsp;aplicaci&oacute;n PRESTER &nbsp;solicitar&aacute;
        que el Usuario env&iacute;e una fotograf&iacute;a de su rostro (selfie),
        junto con una foto de su DNI (frente y dorso). Dichas acciones
        constituyen actos jur&iacute;dicos v&aacute;lidos y vinculantes para el
        usuario.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >16. Siguiendo el procedimiento indicado en el sitio y la
        aplicaci&oacute;n PRESTER, el </span
      ><span>Usuario</span
      ><span>&nbsp;podr&aacute; activar su &ldquo;Cuenta del </span
      ><span>Usuario</span
      ><span class="c0"
        >&rdquo;. Una vez activada la Cuenta del Usuario, &eacute;ste
        tendr&aacute; acceso a la aplicaci&oacute;n y/o los servicios del sitio
        y la aplicaci&oacute;n PRESTER mediante el uso del mismo aparato
        telef&oacute;nico m&oacute;vil en el que realiz&oacute; la
        registraci&oacute;n. En caso de cambiar de aparato deber&aacute; bajar
        nuevamente la aplicaci&oacute;n y cargar sus datos actualizados. Cuando
        el usuario desee utilizar dinero disponible en su cuenta para realizar
        compras y/o pagos, deber&aacute; gestionar una contrase&ntilde;a a
        trav&eacute;s de la aplicaci&oacute;n PRESTER &nbsp;en su celular.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >17. El Usuario podr&aacute; modificar su contrase&ntilde;a en cualquier
        momento y se obliga a preservar tal contrase&ntilde;a bajo absoluta
        confidencialidad y, a no revelarla o compartirla con otras personas bajo
        ning&uacute;n concepto. A todo evento queda suficientemente establecido
        que el Usuario es exclusivo responsable por el uso de la
        contrase&ntilde;a en el sitio y la aplicaci&oacute;n
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >18. PRESTER &nbsp;y asume las consecuencias derivadas de ello. No
        obstante ello, el Usuario se obliga a notificar a PRESTER &nbsp;en forma
        inmediata y por medio fehaciente de cualquier uso no autorizado de su
        contrase&ntilde;a y/o del ingreso al sitio y la aplicaci&oacute;n
        PRESTER &nbsp;y/o a su Cuenta por terceros no autorizados por &eacute;l.
        PRESTER &nbsp;se reserva el derecho a rechazar el Pedido de
        Activaci&oacute;n as&iacute; como el de cancelar una activaci&oacute;n
        previamente aceptada, en caso de que considere que existan razones
        suficientes para ellos, as&iacute; como cuando considere que el Usuario
        no cumpla con los requisitos m&iacute;nimos establecidos en la
        cl&aacute;usula 6 del presente.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span>19. La aplicaci&oacute;n </span><span>PRESTER</span
      ><span class="c0"
        >&nbsp; que el Usuario instala en su dispositivo cuenta con cuatro
        apartados: a) Mis datos personales, donde el Usuario puede consultar los
        datos personales ingresados por &eacute;l mismo al momento de solicitar
        un pr&eacute;stamo y/u otro de los servicios prestados por PRESTER , b)
        Mi cuenta, donde puede ver los pr&eacute;stamos que solicit&oacute; a
        PRESTER , si estos fueron aprobados o rechazados, su saldo actual,
        cantidad de cuotas y otra informaci&oacute;n relevante referida a dichos
        pr&eacute;stamos y/o cualquier otro servicio prestado por PRESTER , c)
        Mensajes: Aqu&iacute; quedan registrados los mensajes enviados por
        PRESTER &nbsp;comunicando rechazos o aprobaciones de pr&eacute;stamos,
        avisos de transferencia realizada, avisos de vencimiento de cuota, y
        otros avisos en relaci&oacute;n a los servicios prestados por PRESTER
        &nbsp;que el Usuario haya solicitado, d) Contacto: Desde aqu&iacute; el
        Usuario puede contactar a PRESTER &nbsp;por cualquier consulta,
        adem&aacute;s se muestran todos los medios de contacto.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >20. En caso de no poder acceder al apartado MI CUENTA, por
        p&eacute;rdida del tel&eacute;fono m&oacute;vil vinculado, o por
        cualquier otra raz&oacute;n, deber&aacute; solicitar a PRESTER
        &nbsp;copia del mismo, por medio de la cuenta de e-mail: </span
      ><span class="c0">info@prester.com.ar. </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >21. En ning&uacute;n caso la falta de acceso al apartado MI CUENTA
        podr&aacute; ser invocada como causal suficiente para no abonar o
        demorar el pago del d&eacute;bito adeudado y vencido.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >22. La aplicaci&oacute;n PRESTER &nbsp;es intransferible y de uso
        personal. Ante la p&eacute;rdida del tel&eacute;fono m&oacute;vil, el
        Usuario, deber&aacute; dar inmediato aviso a i</span
      ><span>nfo@prester.com.ar</span
      ><span>&nbsp;o al tel&eacute;fono +549 11 6350 4807</span><span>.</span
      ><span
        >&nbsp;El Usuario no responder&aacute; por el uso por parte de terceros
        de la Aplicaci&oacute;n PRESTER &nbsp;sustra&iacute;da o extraviada a
        partir de la hora cero (0) del d&iacute;a siguiente al de la denuncia
        respectiva, pero responder&aacute;n ilimitadamente por los usos
        anteriores. La Aplicaci&oacute;n PRESTER &nbsp;es de propiedad de
        PRESTER &nbsp;siendo el Usuario el depositario de sus Servicios. Estas
        son para uso de consumos personales y no ser&aacute;n usadas con fines
        comerciales o financieros, ni luego de su vencimiento o
        anulaci&oacute;n. El Usuario ser&aacute; responsable de la
        Aplicaci&oacute;n PRESTER &nbsp;solicitada, por su uso incorrecto,
        guarda, conservaci&oacute;n, y por todas las consecuencias del uso hasta
        su vencimiento o anulaci&oacute;n, PRESTER quedar&aacute; facultada para
        inhabilitar la aplicaci&oacute;n PRESTER &nbsp;a nombre del </span
      ><span>Usuario</span><span class="c0">. </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >23. El Usuario entiende que el sitio y la aplicaci&oacute;n PRESTER
        &nbsp;es un sistema inform&aacute;tico y que, como cualquier
        creaci&oacute;n humana, no est&aacute; exenta de errores. En este
        sentido, PRESTER &nbsp;no puede garantizar que el sitio web y la
        aplicaci&oacute;n PRESTER &nbsp;funcionar&aacute; sin errores (bugs).
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >24. PRESTER &nbsp;no garantiza el acceso, uso continuado o
        ininterrumpido del sitio y la aplicaci&oacute;n PRESTER . El Usuario
        entiende que el sitio web y la aplicaci&oacute;n PRESTER &nbsp;puede no
        estar disponible debido a dificultades t&eacute;cnicas o fallas de
        Internet o por cualquier otra circunstancia, incluso ajena a la
        responsabilidad de PRESTER .
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >25. En ning&uacute;n caso PRESTER &nbsp;ser&aacute; responsable por las
        demoras y/o problemas de cualquier especie que surjan en los medios o
        mecanismos de cobro y/o desembolso de los pr&eacute;stamos, utilizados
        en relaci&oacute;n a los mismos.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >26. No obstante lo dispuesto precedentemente, PRESTER &nbsp;se
        compromete a realizar sus mejores esfuerzos para procurar restablecer el
        sistema con la mayor celeridad posible sin que por ello pueda
        imput&aacute;rsele alg&uacute;n tipo de responsabilidad.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >27. El Usuario garantizar&aacute; y responder&aacute; en cualquier caso
        por la veracidad, exactitud, vigencia y autenticidad de los datos
        facilitados y se comprometer&aacute; a mantenerlos debidamente
        actualizados.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >28. El Usuario autoriza a PRESTER a confirmar los datos suministrados
        acudiendo a entidades p&uacute;blicas, compa&ntilde;&iacute;as
        especializadas o centrales de riesgo, como as&iacute; tambi&eacute;n
        evaluar atributos de comportamiento en funci&oacute;n del uso del
        celular en el que se encuentre instalada la aplicaci&oacute;n. La
        informaci&oacute;n que PRESTER &nbsp;obtenga de estas consultas,
        as&iacute; como la proporcionada por el usuario, ser&aacute; tratada en
        forma confidencial y empleada exclusivamente para evaluar la solicitud
        de cr&eacute;dito recibida y no ser&aacute; de ninguna forma compartida
        con otras empresas o personas f&iacute;sicas ajenas a PRESTER . Dichos
        datos tendr&aacute;n el tratamiento especificado en el apartado VII del
        presente documento.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >29. Expresamente el Usuario presta conformidad para que PRESTER
        &nbsp;pueda informar del otorgamiento de cada Pr&eacute;stamo a las
        bases de datos de las distintas centrales de riesgo crediticio
        p&uacute;blicas y privadas, y con posterioridad a ello, acerca del
        cumplimiento o eventual incumplimiento del usuario respecto de las
        condiciones de pago convenidas.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >30. PRESTER &nbsp;podr&aacute; solicitar al Usuario la
        presentaci&oacute;n o env&iacute;o de cualquier comprobante y/o dato
        adicional que considere necesario a efectos de confirmar y/o verificar
        sus datos personales.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19"
        >&nbsp;IV. Solicitud de Servicios de Cr&eacute;dito.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;31. El Usuario, luego de ingresar a su Cuenta en el sitio y la
        aplicaci&oacute;n PRESTER , podr&aacute; solicitar a PRESTER , en todo
        momento, el otorgamiento de una asistencia en dinero (el
        &ldquo;Pr&eacute;stamo&rdquo;) indicando (i) el monto de la misma, y
        (ii) el plazo de devoluci&oacute;n que propone, y (iii) la cuenta
        bancaria identificada por su CBU (clave bancaria &uacute;nica) en la que
        se acreditar&aacute; y debitar&aacute; el otorgamiento y
        devoluci&oacute;n del Pr&eacute;stamo respectivamente (la
        &ldquo;Solicitud&rdquo;). En caso de no poseer una cuenta, PRESTER
        &nbsp;podr&aacute; considerar habilitar otros canales para el desembolso
        y pago del Pr&eacute;stamo, como sucursales PRESTER &nbsp;o bien bocas
        de terceros habilitados a tal fin.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span>32. </span
      ><span
        >Una vez recibida la Solicitud, en un plazo no mayor a 5 d&iacute;as
        h&aacute;biles, PRESTER &nbsp;comunicar&aacute; la decisi&oacute;n de
        rechazo o aprobaci&oacute;n al Usuario. En el caso de la
        aplicaci&oacute;n m&oacute;vil informar&aacute; mediante
        notificaci&oacute;n push a la app que el usuario instal&oacute; o bien
        mediante correo electr&oacute;nico: (i) el monto del Pr&eacute;stamo
        susceptible de ser acordado, y (ii) el monto y las fechas de vencimiento
        de las cuotas del Pr&eacute;stamo, y (iii) los t&eacute;rminos y
        condiciones del cr&eacute;dito para ser aceptados por el cliente.</span
      ><span>&nbsp;Para el caso del sitio web, informar&aacute; mediante </span
      ><span>notificaci&oacute;n</span
      ><span class="c0"
        >&nbsp;v&iacute;a correo electr&oacute;nico: &nbsp;(i) el monto del
        Pr&eacute;stamo susceptible de ser acordado, y (ii) el monto y las
        fechas de vencimiento de las cuotas del Pr&eacute;stamo, y (iii) los
        t&eacute;rminos y condiciones del cr&eacute;dito por el cliente.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >33. PRESTER se reserva el derecho de rechazar la Solicitud de
        Pr&eacute;stamo efectuada por un Usuario cuando est&eacute; presente
        antecedentes crediticios desfavorables y/o no posea ingresos de acuerdo
        al monto de cuota que deba abonar, contemplando que esta no </span
      ><span>supere el 30% de su ingreso</span
      ><span class="c0"
        >, de forma de no afectar su capacidad de pago respecto de sus gastos
        habituales
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >34. El Usuario, una vez recibida la Propuesta de Pr&eacute;stamo,
        comunicar&aacute; a PRESTER , en el sitio y la aplicaci&oacute;n , la
        aceptaci&oacute;n de la Propuesta de Pr&eacute;stamo (la
        &ldquo;Aceptaci&oacute;n&rdquo;).
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >35. La Aceptaci&oacute;n obligar&aacute; a PRESTER a liquidar en un
        plazo no mayor a 5 d&iacute;as h&aacute;biles el Pr&eacute;stamo en las
        condiciones detalladas en la Propuesta de Pr&eacute;stamo.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >36. PRESTER enviar&aacute; a un correo electr&oacute;nico verificado
        del cliente copia de estos t&eacute;rminos y condiciones junto con el
        pr&eacute;stamo liquidado, cantidad y montos de cuota a abonar junto con
        las fechas de vencimiento
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >37. El Usuario entiende y reconoce que la Solicitud y la
        Aceptaci&oacute;n, implica la aceptaci&oacute;n de los presentes
        T&eacute;rminos y Condiciones.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;38. PRESTER, en todo momento, cumplir&aacute; con las normas
        fiscales vigentes en la Rep&uacute;blica Argentina a cuyos fines
        proceder&aacute; a emitir las facturas que correspondan.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >39. El Usuario podr&aacute; revocar la aceptaci&oacute;n de la
        Propuesta del Pr&eacute;stamo y/o cualquier otro servicio que se le haya
        otorgado desde el sitio y la aplicaci&oacute;n PRESTER &nbsp;dentro de
        los diez (10) h&aacute;biles bancarios computados a partir de la
        aceptaci&oacute;n de la misma. Para efectuar su derecho de
        revocaci&oacute;n, el Usuario deber&aacute; notificar a PRESTER &nbsp;su
        decisi&oacute;n por medio del bot&oacute;n de arrepentimiento de la
        p&aacute;gina web </span
      ><span>www.prester.com.ar </span
      ><span class="c0"
        >o escribiendo un mail a reclamos@prester.com.ar, o mediante la
        devoluci&oacute;n del dinero del Pr&eacute;stamo y/o del servicio
        solicitado dentro del plazo de diez (10) d&iacute;as h&aacute;biles
        bancarios; en cuyo caso se deber&aacute;n los intereses y gastos que
        correspondieran.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >40. El sitio y la aplicaci&oacute;n PRESTER podr&aacute;n ofrecer la
        financiaci&oacute;n de productos y servicios, incluyendo pero no
        limit&aacute;ndose a los siguientes: motos, bicicletas, materiales para
        la construcci&oacute;n y/o mejora del hogar, electrodom&eacute;sticos,
        viajes. Esta operatoria se realizar&aacute; en acuerdo con empresas
        dedicadas y especializadas en dicho rubro (partners), que
        ofrecer&aacute;n la financiaci&oacute;n de PRESTER como una de las
        formas de acceder a dichos productos o servicios. En estos casos el
        cliente podr&aacute; realizar dichas operaciones tanto de manera
        presencial en alguno de dichos comercios como de forma remota a
        trav&eacute;s de los sitios de comercio electr&oacute;nico de los
        partners. Los partners podr&aacute;n utilizar folleter&iacute;a y/o
        campa&ntilde;as en medios digitales para promover estas alianzas
        comerciales. El cliente en estos casos realizar&aacute; una solicitud de
        cr&eacute;dito al igual que si solicitara un cr&eacute;dito en efectivo,
        con la diferencia que el monto de la financiaci&oacute;n ser&aacute;
        transferido al Comercio que realiza la venta financiada. El cliente
        autoriza e instruye a PRESTER a realizar dicha acreditaci&oacute;n
        previa verificaci&oacute;n de PRESTER de la entrega del producto o
        contrato de servicio al cliente. Dicha entrega quedar&aacute; confirmada
        con la firma del cliente en la factura o remito correspondiente. La
        forma de pago de cuotas de estos cr&eacute;ditos es la misma que para
        los pr&eacute;stamos con acreditaci&oacute;n en cuenta del
        cliente.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;41. PRESTER podr&aacute; contratar servicios de proveedores de
        comercializaci&oacute;n de pr&eacute;stamos, quienes tendr&aacute;n la
        tarea de realizar convenios con comercios que participen del sistema de
        cr&eacute;ditos
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >42. PRESTER contrata servicios de comercializadores quienes se
        ocupar&aacute;n de reunir documentaci&oacute;n y verificar el disponible
        en el caso de pr&eacute;stamos a empleados del sector p&uacute;blico
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >43. PRESTER &nbsp;no cobra ning&uacute;n cargo por adelantado ni
        autoriza al cobro de ning&uacute;n cargo por la gesti&oacute;n de un
        pr&eacute;stamo previo a su otorgamiento. Las comisiones se abonan al
        momento del desembolso, pudiendo descontarse del capital o bien
        capitalizarse y luego ser abonadas a lo largo del pr&eacute;stamo
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >44. En caso de duda de alg&uacute;n consumidor respecto de si un
        comercializador est&aacute; autorizado por PRESTER &nbsp;podr&aacute;n
        canalizar todas sus consultas al mail o al celular/whatsapp que figura
        en medios de contacto.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3"><span class="c19">V. Precio de los Servicios. </span></p>
    <p class="c1"><span class="c0"></span></p>

    <p class="c3">
      <span class="c0"
        >45. El Usuario al comunicar la Aceptaci&oacute;n de la Propuesta de
        Pr&eacute;stamo, siempre que el Pr&eacute;stamo sea acreditado en su
        cuenta bancaria, reconoce y acepta que deber&aacute; a PRESTER o la suma
        que &eacute;ste indique en la Propuesta de Pr&eacute;stamo, a la que se
        le deben adicionar los intereses (antes de IVA) detallados a
        continuaci&oacute;n:
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >Intereses compensatorios: Los intereses se calcular&aacute;n mediante
        una Tasa Nominal Anual (TNA) sin IVA M&iacute;nima de
        {{ $tasas().TnaSinIvaMin }}% y M&aacute;xima de
        {{ $tasas().TnaSinIvaMax }}%.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >El TEA m&iacute;nimo es de {{ $tasas().TeaSinIvaMin }}%, TEA
        m&aacute;ximo {{ $tasas().TeaSinIvaMax }}%. El CFTNA con IVA
        m&iacute;nimo es de {{ $tasas().CftnaConIvaMin }}% y el m&aacute;ximo es
        de {{ $tasas().CftnaConIvaMax }}%.No se cobran otros cargos o
        comisiones. La tasa de inter&eacute;s var&iacute;a en funci&oacute;n de
        los antecedentes crediticios del soli</span
      ><span
        >citante con PRESTER &nbsp;y/o con el mercado de cr&eacute;ditos y su
        capacidad de repago estimada. La TNA, TEA y el CFT se informan durante
        el proceso de solicitud por el sitio y la app, antes de su otorgamiento,
        debiendo el cliente aceptar estos t&eacute;rminos y condiciones como
        as&iacute; tambi&eacute;n los intereses, comisiones y gastos a abonar.
        El sistema de amortizaci&oacute;n utilizado es el sistema franc&eacute;s
        en cuotas iguales. Plazo m&iacute;nimo 3 meses y</span
      ><span>&nbsp;m&aacute;ximo 24 </span><span>meses</span><span>.</span
      ><span
        >&nbsp;En el caso de pr&eacute;stamos otorgados por descuento de
        haberes, de existir gastos de comercializaci&oacute;n, estos se
        informar&aacute;n al cliente al momento de la aceptaci&oacute;n y previo
        al d</span
      ><span class="c0"
        >esembolso, debiendo el cliente dar su conformidad.
      </span>
    </p>

    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >46. Ejemplo: Monto solicitado de ${{ $tasas().EjemploMonto }} a
        {{ $tasas().EjemploPeriodo }} meses | TNA (sin IVA):
        {{ $tasas().EjemploTnaSinIva }}% &ndash; TEA (sin IVA):
        {{ $tasas().EjemploTeaSinIva }}% &ndash; CFTNA (con IVA):
        {{ $tasas().EjemploCftnaConIva }}% |&ndash; CFTEA (con IVA):
        {{ $tasas().EjemploCfteaConIva }}% | Cuota: ${{
          $tasas().EjemploCuota
        }}
        |Total a pagar: ${{ $tasas().EjemploTotalaPagar }}.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >47. PRESTER &nbsp;podr&aacute; aceptar cancelaciones anticipadas
        parciales o totales de los Pr&eacute;stamos, las cuales se podr&aacute;n
        efectuar en cualquier fecha, debiendo abonarse en tal caso las cuotas
        devengadas hasta el momento m&aacute;s el capital restante. No se
        cobrar&aacute;n cargos o comisiones por cancelaci&oacute;n.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >48. La mora en el cumplimiento de las obligaciones asumidas mediante la
        presente, se producir&aacute; de pleno derecho, e implicar&aacute; la
        caducidad autom&aacute;tica de todo plazo que se encontrar&aacute;
        pendiente con lo que resultar&aacute; exigible el saldo total lo
        adeudado, con m&aacute;s los correspondientes intereses compensatorios,
        moratorios y punitorios pactados, y los gastos de gesti&oacute;n
        relacionados a la mora. En caso de </span
      ><span>mora</span
      ><span class="c0"
        >, el importe adeudado a dicho momento devengar&aacute; adem&aacute;s
        estos conceptos: a) Intereses punitorios: Estos ser&aacute;n
        equivalentes al 50% de los intereses compensatorios pactados en la
        Solicitud aceptada. b) Gastos de gesti&oacute;n de mora: En caso de
        atraso o mora en el pago por parte del cliente por un per&iacute;odo
        superior a 90 d&iacute;as PRESTER deber&aacute; contratar servicios de
        terceros para el recupero de la deuda. Dichos costos ser&aacute;n
        trasladados al cliente y representan un 20% de la deuda reclamada.
        PRESTER &nbsp;gestionar&aacute; mediante recursos propios la mora
        temprana (hasta 90 d&iacute;as) en forma telef&oacute;nica, por
        whatsapp, mail, SMS, cartas, y otros medios. Dichos gastos no se
        cargar&aacute;n al cliente. Al alcanzar los 180 d&iacute;as de mora, su
        caso ser&aacute; derivado a un Estudio Jur&iacute;dico, debiendo el
        cliente afrontar los gastos y honorarios judiciales o extrajudiciales
        que se devenguen.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >49. PRESTER en calidad de Tomador podr&aacute; contratar, a su cargo,
        un seguro de vida que cubrir&aacute; el fallecimiento o la incapacidad
        total y permanente como consecuencia de enfermedad o accidente, por una
        suma asegurada actualizable equivalente al saldo de las deudas que
        mantenga con PRESTER &nbsp;por cualquier concepto, al momento del
        siniestro, en las condiciones y hasta la suma m&aacute;xima que se
        establezca en la p&oacute;liza respectiva. Adem&aacute;s PRESTER
        &nbsp;podr&aacute; brindar el servicio a los Usuarios que as&iacute; lo
        soliciten de subcontrataci&oacute;n de seguros de vida,
        protecci&oacute;n de bolso y/o celular, seguro del hogar, seguros de
        ahorro y retiro, seguro de saldo deudor y/o cualquier otro seguro que en
        el futuro PRESTER &nbsp;decida a su s&oacute;lo criterio ofrecer a los
        Usuarios, en alguna compa&ntilde;&iacute;a del mercado o tambi&eacute;n
        podr&aacute; cederlos a una entidad financiera que luego subcontrate a
        una compa&ntilde;&iacute;a de seguros. Estos productos ser&aacute;n
        ofrecidos a los Usuarios que as&iacute; lo soliciten, con su
        consentimiento previo.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >50. Todos los impuestos actuales o futuros que gravaren esta
        operaci&oacute;n est&aacute;n a cargo del Usuario.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >51. PRESTER &nbsp;/ SIFT S.A podr&aacute; ceder en cualquier momento
        los cr&eacute;ditos actuales o futuros que tuviere en contra del Usuario
        Titular, derivados de la utilizaci&oacute;n del sitio web o la App
        PRESTER , sin necesidad de notificaci&oacute;n por acto p&uacute;blico,
        en los t&eacute;rminos del Art. 72 de la ley 24.441 a los efectos de su
        validez por ante terceros y/o deudor cedido.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19">VI. Pago de las cuotas del pr&eacute;stamo </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >52. El Usuario, en este acto, mediante la aceptaci&oacute;n de los
        T&eacute;rminos y Condiciones, se compromete al pago de la totalidad de
        las cuotas del pr&eacute;stamo
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >53. El Usuario abonar&aacute; en las fechas pautadas y por los montos
        confirmados por &eacute;l mismo en la aplicaci&oacute;n al aceptar la
        propuesta de pr&eacute;stamo los siguientes conceptos: el capital,, los
        intereses compensatorios e IVA, as&iacute; como tambi&eacute;n, de
        producirse, los siguientes: intereses punitorios y gastos de
        gesti&oacute;n de cobranzas.</span
      >
    </p>
    <p class="c1"><span class="c22"></span></p>
    <p class="c3">
      <span>54. </span><span>El </span><span>pago de las cuotas</span
      ><span
        >&nbsp;se podr&aacute; cursar, entre otros, a trav&eacute;s de los
        siguientes medios: a) &nbsp;mediante una tarjeta de d&eacute;bito del
        usuario, para lo cual SIFT podr&aacute; contratar un servicio de
        terceros que gestione dichas suscripciones a d&eacute;bito
        autom&aacute;tico como por ejemplo MOBBEX y/u otra empresa similar, b)
        descuento de haberes en caso de convenio entre su empleador y/o mutual
        y/o cooperativa con FINDO, c) &nbsp;el Usuario expresamente autoriza a
        SIFT S.A. y/o &Eacute;pico S.A. y/o Major Contractor Group S.A. y/o
        Mutual 20 de Septiembre y/o M&amp;G Negocios S.A.S. y/o Cooperativa
        Creditan y/o cualquier otra empresa que SIFT S.A. autorice, a percibir
        el capital, los intereses moratorios y la penalidad por mora del mutuo
        mediante la operatoria de d&eacute;bito interno o intrabancario y/o
        DEBIN Spot, descontando tales importes de cualquier cuenta bancaria de
        su titularidad, d</span
      ><span
        >) en cualquier boca de atenci&oacute;n de Pago F&aacute;cil indicando
        su n&uacute;mero de DNI e indicando que desea abonar a PRESTER</span
      ><span
        >, e) mediante el servicio pagomiscuentas, f) a trav&eacute;s de
        transferencia bancaria a una cuenta de SIFT S.A., g) en el domicilio de
        pago establecido al final de la presente, h) alg&uacute;n otro medio
        disponible. La cancelaci&oacute;n de los montos adeudados por el Usuario
        a SIFT S.A. ser&aacute; efectuada al momento en que SIFT S.A. perciba la
        totalidad de los importes adeudados.
      </span>
    </p>
    <p class="c23 c27"><span class="c5"></span></p>
    <p class="c23">
      <span
        >55. El Usuario se compromete a notificar a PRESTER &nbsp;ante un cambio
        del CBU con el que opera habitualmente. Dicha notificaci&oacute;n
        deber&aacute; ser de manera fehaciente y con la suficiente
        antelaci&oacute;n a la fecha correspondiente al pr&oacute;ximo
        vencimiento
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c23">
      <span>56.</span
      ><span
        >&nbsp;En caso de que, en cualquier momento durante la vigencia del
        Pr&eacute;stamo, por cualquier circunstancia, el Usuario incumpliere con
        el pago &iacute;ntegro de cualesquiera de las Cuotas en los
        t&eacute;rminos convenidos y/o abonare una o varias Cuotas s&oacute;lo
        parcialmente, PRESTER imputar&aacute; las cantidades parciales recibidas
        seg&uacute;n el siguiente orden de prelaci&oacute;n: (i) intereses
        punitorios e IVA, ii) gastos de gesti&oacute;n de cobranzas e IVA, iii)
        intereses compensatorios e IVA, iv) gastos judiciales, v) capital.</span
      >
    </p>
    <p class="c23 c27"><span class="c5"></span></p>
    <p class="c3">
      <span class="c0"
        >57. PRESTER &nbsp;podr&aacute;, a su solo criterio, otorgar al Usuario,
        ante un pedido de &eacute;ste, una extensi&oacute;n del plazo para el
        repago del monto adeudado del Pr&eacute;stamo con las formalidades que
        establezca y previa informaci&oacute;n y aceptaci&oacute;n de las
        condiciones por parte del Usuario.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19"
        >&nbsp;VII. Pol&iacute;tica de Privacidad y Protecci&oacute;n de Datos
        Personales.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >58. Al prestar conformidad por los presentes T&eacute;rminos y
        Condiciones, el Usuario tambi&eacute;n est&aacute; prestando conformidad
        a la Pol&iacute;tica de Privacidad y a todos los derechos y obligaciones
        contenidos en el sitio y la aplicaci&oacute;n PRESTER. Se
        considerar&aacute; que (i) el acceso de cualquier visitante al sitio y
        la aplicaci&oacute;n PRESTER, en forma ocasional o frecuente, o (ii) la
        utilizaci&oacute;n de alguno de los servicios prestados por el sitio y
        la aplicaci&oacute;n PRESTER , o (iii) su registro en el sitio y la
        aplicaci&oacute;n PRESTER , implica la aceptaci&oacute;n de estos
        T&eacute;rminos y Condiciones Generales y la Pol&iacute;tica de
        Privacidad y tienen car&aacute;cter obligatorio y vinculante para todos
        los Usuarios.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;59. El Usuario declara y manifiesta con relaci&oacute;n a sus
        datos personales, es decir, toda informaci&oacute;n suministrada a
        PRESTER &nbsp;y recabada por &eacute;sta, vinculada al servicio objeto
        de la presente (los &ldquo;Datos Personales&rdquo;), que presta
        consentimiento para que: (i) los mismos sean utilizados para la
        consideraci&oacute;n de cualquier pr&eacute;stamo y/o servicio que pueda
        solicitar a PRESTER &nbsp;y para cualquier ofrecimiento que PRESTER
        &nbsp;pueda efectuarle en el futuro; (ii) PRESTER &nbsp;pueda informar
        la situaci&oacute;n de pago a las agencias de informes crediticios y al
        Banco Central de la Rep&uacute;blica Argentina en los t&eacute;rminos
        del Art. 26 de la Ley 25.326 y su reglamentaci&oacute;n, (iii) todos los
        Datos, o informaci&oacute;n de cualquier tipo, que entregar&aacute; a
        PRESTER pueda ser procesada y/o transferida a sociedades vinculadas,
        controlantes, controladas y/o afiliadas a PRESTER.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >60. Los Datos solicitados en la aplicaci&oacute;n y/o el sitio de
        PRESTER resultan indispensables para la evaluaci&oacute;n de los
        pr&eacute;stamos, por lo cual deben ser obligatoriamente aportados por
        el Usuario en forma exacta, veraz y actualizada
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >61. El Usuario, titular de los datos personales, autoriza a PRESTER
        &nbsp;para la conservaci&oacute;n, procesamiento y transferencia de los
        Datos cuando &eacute;stos sean requeridos por otras entidades
        financieras, organismos p&uacute;blicos y/o autoridades judiciales; y en
        los casos en los que lo autorice la Ley Nacional de Protecci&oacute;n de
        Datos Personales.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >62. Asimismo, el Usuario toma conocimiento y autoriza a PRESTER
        expresamente a recabar todos los datos crediticios y/o bancarios del
        Usuario de bases de datos p&uacute;blicas, privadas y/o de empresas
        especializadas (a saber: n&ordm; de cuenta/s, tipo de cuenta/s y banco/s
        donde est&eacute;/n abierta/s la/s misma/s, entre otros) que considere
        necesarios para analizar o efectivizar el otorgamiento o el cobro de un
        pr&eacute;stamo.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >63. En cumplimiento con la Ley Nacional de Protecci&oacute;n de Datos
        personales y la restante normativa vigente, PRESTER
        &nbsp;brindar&aacute; atenci&oacute;n a las consultas y pedidos de
        acceso a la informaci&oacute;n (art&iacute;culo 14 de la Ley 25.326).
        Adem&aacute;s, el derecho a la rectificaci&oacute;n,
        actualizaci&oacute;n o supresi&oacute;n de los datos personales
        inexactos o incompletos podr&aacute; ejercerse sin cargo alguno para el
        interesado (art&iacute;culo 19 de la Ley 25.326). El derecho de acceso
        solo puede ser ejercido en forma gratuita a intervalos no inferiores a
        seis meses, salvo que se acredite un inter&eacute;s leg&iacute;timo al
        efecto (inc. </span
      ><span>3 &nbsp;art&iacute;culo</span
      ><span class="c0"
        >&nbsp;14 de la Ley 25.326). Asimismo, de acuerdo con lo establecido en
        el art&iacute;culo 26 de la Ley 25.326, solo se podr&aacute;n archivar,
        registrar o ceder los datos personales que sean significativos para
        evaluar la solvencia econ&oacute;mico-financiera de los afectados
        durante los &uacute;ltimos cinco a&ntilde;os. Dicho plazo se
        reducir&aacute; a dos a&ntilde;os cuando el deudor cancele o de otro
        modo extinga la obligaci&oacute;n, debi&eacute;ndose hacer constar dicho
        hecho.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19">VIII. Cl&aacute;usulas adicionales. </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >64. El Usuario en oportunidad del uso del sitio y la aplicaci&oacute;n
        PRESTER &nbsp;se obliga a no ejecutar y/o realizar operaciones y/o actos
        que pongan en riesgo, o generen da&ntilde;os inmediatos o mediatos, a la
        operaci&oacute;n del sitio y la aplicaci&oacute;n PRESTER . En tal
        evento PRESTER y/o el sitio y la aplicaci&oacute;n PRESTER podr&aacute;n
        suspender temporal o definitivamente al Usuario y/o cancelar su Cuenta,
        sin perjuicio del cumplimiento de las obligaciones contra&iacute;das y
        de las acciones legales que pudieran corresponder, particularmente en
        relaci&oacute;n a los da&ntilde;os causados.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span>65. </span
      ><span>PRESTER y/o el sitio y la aplicaci&oacute;n PRESTER, e</span
      ><span
        >n un fiel reflejo de su responsabilidad empresaria, informa al Usuario
        que el sitio y la aplicaci&oacute;n PRESTER es de acceso voluntario por
        &eacute;l, sujeto a los T&eacute;rminos y Condiciones y la
        Pol&iacute;tica de Privacidad, para el uso y/o goce de los servicios,
        informaci&oacute;n o entretenimientos que el sitio y la
        aplicaci&oacute;n PRESTER contiene y brinda. </span
      ><span>En </span><span>consecuencia</span
      ><span
        >, PRESTER y/o el sitio y la aplicaci&oacute;n PRESTER informan</span
      ><span class="c0"
        >&nbsp;al Usuario que no asumen ninguna responsabilidad adicional a las
        que se fijan espec&iacute;ficamente en los T&eacute;rminos y Condiciones
        Generales y la Pol&iacute;tica de Privacidad.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span>66</span
      ><span>. PRESTER y/o el sitio y la aplicaci&oacute;n PRESTER dejan c</span
      ><span class="c0"
        >laramente establecido que no asumen ning&uacute;n tipo de
        responsabilidad por la publicidad de terceros que contenga el sitio y la
        aplicaci&oacute;n PRESTER ni ser&aacute;n responsables por las
        vinculaciones que el Usuario establezca con ellos y/o con otros Usuarios
        ni ser&aacute;n responsables por las eventuales consecuencias, de
        cualquier tipo que fuera, resultantes de fallas en el sistema, en el
        servidor o en Internet que pudieran surgir del acceso y/o uso del sitio
        y la aplicaci&oacute;n PRESTER bajo cualquier modalidad que el usuario
        empleara ni ser&aacute;n responsables por eventuales errores u omisiones
        contenidos en el sitio y la aplicaci&oacute;n PRESTER .
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >67. Las aplicaciones, servicios y contenidos que provee el sitio y la
        aplicaci&oacute;n PRESTER fueron dise&ntilde;ados para el uso por parte
        de los Usuarios. Los programas, bases de datos, redes y archivos que
        permiten al Usuario acceder y usar su Cuenta son de propiedad de PRESTER
        y est&aacute;n protegidos por las leyes y tratados internacionales de
        derecho de autor, marcas, patentes, modelos y dise&ntilde;os
        industriales. El uso indebido y la reproducci&oacute;n total o parcial
        de dichos contenidos quedan prohibidos, salvo autorizaci&oacute;n
        expresa y por escrito de PRESTER .
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >68. PRESTER con el consentimiento previo del Usuario a trav&eacute;s
        del sitio o la aplicaci&oacute;n PRESTER podr&aacute; utilizar los datos
        aportados por el Usuario para enviar informaci&oacute;n y/o promociones
        y/o publicidad asociada a trav&eacute;s del sitio y la aplicaci&oacute;n
        PRESTER , correo electr&oacute;nico, tel&eacute;fono m&oacute;vil, o
        bien por nota enviada por correo al domicilio indicado en la Solicitud
        de Pr&eacute;stamo. El Usuario podr&aacute; solicitar el no env&iacute;o
        de material promocional o publicitario en todo momento y bajo cualquiera
        de las formas mencionadas precedentemente.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;69. La obtenci&oacute;n de una Cuenta en el sitio y la
        aplicaci&oacute;n PRESTER por el Usuario es gratuita y constituye una
        condici&oacute;n de procedencia ineludible para el uso y/o goce de las
        aplicaciones y/o servicios que provee el sitio y la aplicaci&oacute;n
        PRESTER .
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >70. El Usuario se compromete a no: (1) transmitir mensajes
        an&oacute;nimos o bajo un seud&oacute;nimo; (2) transmitir mensajes o
        informaci&oacute;n injuriosa, intimidatoria, enga&ntilde;osa,
        difamatoria, obscena, o que viole el derecho a la intimidad o la
        propiedad intelectual o industrial de terceros, o que pueda resultar
        perjudicial para &eacute;stos o para el Titular; (3) transmitir
        ning&uacute;n archivo que contenga virus o cualquier otro elemento
        contaminante o destructivo para el Sitio como pueden ser un robot,
        ara&ntilde;a (spider), gusano, troyano, u otros aparatos
        autom&aacute;ticos o procesos manuales para monitorear o copiar las
        p&aacute;ginas del Sitio o aplicaci&oacute;n PRESTER o la
        informaci&oacute;n contenida; (4) transmitir publicidad, materiales
        promocionales, u ofertas excepto en aquellas &aacute;reas (Ej. Cartelera
        de Avisos clasificados, Carteleras de Mensajes) destinadas a ese fin;
        (5) enviar e-mails no solicitados, en forma masiva, a otros Usuarios;
        (6) intentar tener acceso no autorizado, utilizar el Sitio o
        aplicaci&oacute;n PRESTER en alguna manera que pudiera da&ntilde;ar,
        inhabilitar, sobrecargar o deteriorar su contenido o funcionalidad o
        efectuar cualquier otro acto re&ntilde;ido con la finalidad del Sitio o
        aplicaci&oacute;n PRESTER o con sus T&eacute;rminos y Condiciones.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;71. A todos los efectos emergentes de la Solicitud y del
        Pr&eacute;stamo, el Usuario constituye domicilio en aquel que
        declar&oacute; al momento de la primera Solicitud, oblig&aacute;ndose a
        notificar cualquier cambio a trav&eacute;s de info@prester.com.ar
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span
        >72. Adem&aacute;s de los servicios directos ofrecidos por PRESTER a
        clientes finales, FINDO ofrece sus servicios de desarrollo de
        plataformas tecnol&oacute;gicas aplicadas al otorgamiento de
        pr&eacute;stamos y/o tarjetas de cr&eacute;dito y/o cuentas a otras
        entidades. En estos casos las operaciones que se realicen ser&aacute;n
        entre el cliente final y la empresa a la que FINDO presta servicios, por
        lo que FINDO no posee responsabilidad sobre dicha operatoria. Sin
        perjuicio de ello, FINDO se compromete a supervisar que la empresa
        proveedora de cr&eacute;ditos que utilice los servicios de FINDO, preste
        sus servicios </span
      ><span>adecuadamente</span><span class="c0">. </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19"
        >IX. Caso Especial. Autorizaci&oacute;n de Descuento.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;73. Las cl&aacute;usulas de la presente secci&oacute;n X son
        aplicables a Usuarios que resulten empleados afiliados o afiliables a
        una entidad Mutual y/o Sindicato y/o Cooperativa que posea convenio de
        descuento de haberes con empleadores p&uacute;blicos o privados, y que a
        su vez posean convenio de cooperaci&oacute;n con PRESTER para la
        generaci&oacute;n, administraci&oacute;n o gesti&oacute;n de
        cr&eacute;ditos otorgados por PRESTER .
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >74. Las cl&aacute;usulas de la presente secci&oacute;n X son aplicables
        a Usuarios que resulten empleados afiliados o afiliables a una entidad
        Mutual y/o Sindicato y/o Cooperativa que posea convenio de descuento de
        haberes con empleadores p&uacute;blicos o privados, y que a su vez
        posean convenio de cooperaci&oacute;n con PRESTER para la
        generaci&oacute;n, administraci&oacute;n o gesti&oacute;n de
        cr&eacute;ditos otorgados por PRSTER .
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >75. El Usuario autoriza de forma expresa a PRESTER y/o a la entidad a
        gestionar y percibir descuentos de haberes para ser aplicados al cobro
        de las cuotas correspondientes al pr&eacute;stamo. Para dicha
        gesti&oacute;n, PRESTER emitir&aacute; un listado y lo har&aacute;
        llegar a la entidad y/o al empleador del Usuario. Dichos cobros
        ser&aacute;n percibidos por PRESTER y/o quien &eacute;sta designe
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >76. En caso de empleados p&uacute;blicos, estos autorizan a canalizar
        los descuentos correspondientes mediante el C&oacute;digo de Descuento
        en Tesorer&iacute;a de la Municipalidad, Provincia, Naci&oacute;n y/o
        Instituci&oacute;n Titular de los mismos que PRESTER estime pertinente
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >77. A los efectos de descuentos sobre los haberes del Usuario,
        &eacute;ste &uacute;ltimo hace expresa cesi&oacute;n a favor de PRESTER
        , y hasta tanto el Usuario indique lo contrario en forma fehaciente,
        previa conformidad de PRESTER respecto del pago de toda deuda mantenida
        con esta &uacute;ltima
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c19">X. Notificaciones. Domicilio PRESTER . </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >78. El Usuario acepta la plena validez de las notificaciones enviadas a
        PRESTER y recibidas de PRESTER , a trav&eacute;s del sitio y la
        aplicaci&oacute;n PRESTER y/o por correo electr&oacute;nico (en la
        direcci&oacute;n comunicada por el Usuario), destac&aacute;ndose que el
        presente contrato se conviene sobre la base de la buena fe contractual y
        el deber de colaboraci&oacute;n de las partes.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >79. Para todos los efectos de la presente, PRESTER constituye domicilio
        especial en 25 de Mayo 306, Piso 2, CP 1002, Ciudad Aut&oacute;noma de
        Buenos Aires.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >&nbsp;Los presentes T&eacute;rminos y Condiciones se actualizaron el
        14/03/2022.</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "AdhesionContract",
  methods: {},
};
</script>


<style scoped>
/* 
@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");
*/
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c9 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 283.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c6 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 141.6pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c20 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 424.7pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c4 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 70.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c21 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 106.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c13 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 35.4pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c7 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 106.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c22 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 400;
  text-decoration: line-through;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c2 {
  background-color: #ffff00;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: "Arial";
  font-style: normal;
}
.c12 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}
.c25 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}
.c19 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c15 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: "Arial";
  font-style: normal;
}
.c5 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 11pt;
}
.c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
  height: 11pt;
}
.c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c3 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c10 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 26pt;
}
.c14 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c31 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c33 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c32 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: right;
}
.c8 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c28 {
  margin-left: auto;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c26 {
  background-color: #ffffff;
  padding: 22pt 52pt 52pt 52pt;
}
.c27 {
  margin-left: 18pt;
  height: 11pt;
}
.c17 {
  font-size: 9pt;
}
.c11 {
  height: 12.8pt;
}
.c16 {
  height: 27pt;
}
.c30 {
  height: 11pt;
}
.c29 {
  font-weight: 700;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
